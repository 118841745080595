import { useSelector } from "react-redux";
import { UPLOAD_STATES } from "../Constants";

const getExcludeIDs = (state) => {
    const { upload } = state;
    const uploadExcludeIds = { ...upload.toUploadCompletionFiles };
    let excludeIds = new Set;
    Object.entries(uploadExcludeIds).forEach(([key, value]) => {
        if (value.files) {
            Object.values(value.files).forEach(file => {
                if (file.status === UPLOAD_STATES.Uploading)
                    excludeIds.add(parseInt(key));
            })
        }
    })

    return Array.from(excludeIds);
}

const useExecludeIds = () => {
    return useSelector(getExcludeIDs);
}

export default useExecludeIds;