import moment from 'moment';

function checkISO(value) {
  let regExpISO = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;

  return regExpISO.test(value);
}

/**
 * Helper function to convert ms -> HHMMSS
 * @param {number} ms
 * @returns {string}
 */
export function msToHMS(ms, msIncluded = false) {
  // 1- Convert to seconds:
  var seconds = ms / 1000;
  ms = ms % 1000;
  // 2- Extract hours:
  var hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
  seconds = seconds % 3600; // seconds remaining after extracting hours
  // 3- Extract minutes:
  var minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
  // 4- Keep only seconds not extracted to minutes:
  seconds = Math.floor(seconds); // % 60;

  return `${hours}:${minutes}:${seconds}${msIncluded ? `:${ms}` : ''}`
}

export function displayTimeInWaveForm(ms, msIncluded = false) {
  // 1- Convert to seconds:
  var seconds = ms / 1000;
  // 2- Keep the milli-seconds that are not used
  ms = ms % 1000;

  // 2- Extract minutes:
  var minutes = parseInt(seconds / 60); // 60 seconds in 1 minute

  // 3- Keep only seconds not extracted to minutes:
  seconds = seconds % 60 // % 60;
  seconds = Math.floor(seconds);

  return `${String(minutes).length === 1 ? '0' + minutes : minutes}:${String(seconds).length === 1 ? '0' + seconds : seconds}${msIncluded ? `.${Math.floor(ms)}` : ''}`
}

/**
 * Helper function to pretty date
 */
export function prettyDate(time) {
  if (typeof time !== "string" && !time instanceof Date && !checkISO(time)) return;

  let date = new Date(time),
    diff = (new Date().getTime() - date.getTime()) / 1000,
    day_diff = Math.floor(diff / 86400);

  if (isNaN(day_diff) || day_diff < 0) return;

  return (
    (day_diff === 0 &&
      ((diff < 60 && "just now") ||
        (diff < 120 && "1 minute ago") ||
        (diff < 3600 && Math.floor(diff / 60) + " minutes ago") ||
        (diff < 7200 && "1 hour ago") ||
        (diff < 86400 && Math.floor(diff / 3600) + " hours ago"))) ||
    (day_diff === 1 && "Yesterday") ||
    (day_diff < 7 && day_diff + " days ago") ||
    (day_diff < 31 && Math.ceil(day_diff / 7) + " weeks ago") ||
    day_diff + " days ago"
  );
}

/**
 * Helper function to get current timezone
 */
export function currentISODate() {
  let tzOffest = new Date().getTimezoneOffset() * 60000;
  let localISOTime = new Date(Date.now() - tzOffest).toISOString().slice(0, -1);

  return localISOTime;
}

export function formatDateWithTimeStamp(dateToFormat) {
  let formattedDate;
  if (dateToFormat) {
    formattedDate = moment(dateToFormat);
    formattedDate = formattedDate.utcOffset(0);
    formattedDate = formattedDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    formattedDate = formattedDate.toISOString();

  } else {
    formattedDate = null;
  }

  return formattedDate;
}

/**
 * Use formatTimeCallback to style the notch labels as you wish, such
 * as with more detail as the number of pixels per second increases.
 *
 * Here we format as M:SS.frac, with M suppressed for times < 1 minute,
 * and frac having 0, 1, or 2 digits as the zoom increases.
 *
 * Note that if you override the default function, you'll almost
 * certainly want to override timeInterval, primaryLabelInterval and/or
 * secondaryLabelInterval so they all work together.
 *
 * @param: seconds
 */
export function formatWaveformTimeCallback(seconds) {
  const ms = Number(seconds) * 1000;
  return displayTimeInWaveForm(ms.toFixed(2))
}

/**
 * Use timeInterval to set the period between notches, in seconds,
 * adding notches as the number of pixels per second increases.
 *
 * Note that if you override the default function, you'll almost
 * certainly want to override formatTimeCallback, primaryLabelInterval
 * and/or secondaryLabelInterval so they all work together.
 *
 * @param: pxPerSec
 */
 export function waveformTimeInterval(pxPerSec) {
  var retval = 1;
  if (pxPerSec >= 25 * 100) {
    retval = 0.01;
  } else if (pxPerSec >= 25 * 40) {
    retval = 0.025;
  } else if (pxPerSec >= 25 * 10) {
    retval = 0.1;
  } else if (pxPerSec >= 25 * 4) {
    retval = 0.25;
  } else if (pxPerSec >= 25) {
    retval = 1;
  } else if (pxPerSec * 5 >= 25) {
    retval = 5;
  } else if (pxPerSec * 15 >= 25) {
    retval = 15;
  } else {
    retval = Math.ceil(0.5 / pxPerSec) * 60;
  }
  return retval;
 }

 /**
 * Return the cadence of notches that get labels in the primary color.
 * EG, return 2 if every 2nd notch should be labeled,
 * return 10 if every 10th notch should be labeled, etc.
 *
 * Note that if you override the default function, you'll almost
 * certainly want to override formatTimeCallback, primaryLabelInterval
 * and/or secondaryLabelInterval so they all work together.
 *
 * @param pxPerSec
 */
export function waveformPrimaryLabelInterval(pxPerSec) {
  var retval = 1;
  if (pxPerSec >= 25 * 100) {
    retval = 10;
  } else if (pxPerSec >= 25 * 40) {
    retval = 4;
  } else if (pxPerSec >= 25 * 10) {
    retval = 10;
  } else if (pxPerSec >= 25 * 4) {
    retval = 4;
  } else if (pxPerSec >= 25) {
    retval = 1;
  } else if (pxPerSec * 5 >= 25) {
    retval = 5;
  } else if (pxPerSec * 15 >= 25) {
    retval = 15;
  } else {
    retval = Math.ceil(0.5 / pxPerSec) * 60;
  }
  return retval;
}

/**
* Return the cadence of notches to get labels in the secondary color.
* EG, return 2 if every 2nd notch should be labeled,
* return 10 if every 10th notch should be labeled, etc.
*
* Secondary labels are drawn after primary labels, so if
* you want to have labels every 10 seconds and another color labels
* every 60 seconds, the 60 second labels should be the secondaries.
*
* Note that if you override the default function, you'll almost
* certainly want to override formatTimeCallback, primaryLabelInterval
* and/or secondaryLabelInterval so they all work together.
*
* @param pxPerSec
*/
export function waveformSecondaryLabelInterval(pxPerSec) {
 // draw one every 10s as an example
 return Math.floor(10 / waveformTimeInterval(pxPerSec));
}