import React from 'react';
import { Button, Input, Select, Form, Space } from 'antd';
import PhoneCountryCodes from './PhoneCountryCodes';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

const TwoFactorAuthPhoneNumber = ({ submitPhoneNumber, goBackToSignIn }) => {

    const handleSubmit = values => {
        submitPhoneNumber(values.countryCode + values.phoneNumber);
    }

    return (
        <Form {...layout} onFinish={handleSubmit}>

            <p>Please enter your valid mobile number to be used in second factor authentication...</p>

            <Form.Item
                label="Country Code"
                name="countryCode"
                rules={[
                    {
                        required: true,
                        message: "Please select your country!",
                    },
                ]}>
                <Select
                    showSearch
                    placeholder="Select your country..."
                    options={PhoneCountryCodes.map(c => ({ label: `${c.name} (${c.dial_code})`, value: c.dial_code, key: c.code }))}
                    filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
            </Form.Item>

            <Form.Item
                label="Phone Number"
                name="phoneNumber"
                rules={[
                    {
                        required: true,
                        message: "Please enter your phone number!",
                    },
                ]}>
                <Input type='number' placeholder='Type your phone number...' />
            </Form.Item>

            <Form.Item>
                <Space>
                    <Button type='primary' htmlType='submit'> Send Verification Code </Button>
                    <Button type='dashed' onClick={goBackToSignIn}> Go Back To Sign In </Button>
                </Space>
            </Form.Item>

        </Form>
    )
}

export default TwoFactorAuthPhoneNumber;
