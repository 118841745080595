import { lxtBackend } from "./utils";
import config from '../configuration/config';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getContributorCompletions: async () =>
		lxtBackend.get('contributor/completions/'),

	getCompletionId: async (projectId, review = false, excludeIds = []) => {
		return lxtBackend.post(
			`contributor/completions/next/${review ? 'rv' : 'dp'}`,
			{ excludeIds: excludeIds ? excludeIds : [] },
			{ params: { project_id: projectId } },
		);
	},

	getCompletion: async (id, review = false) =>
		lxtBackend.get(`contributor/completions/${id}/${review ? 'rv' : 'dp'}`),

	dpSendCompletionResult: async (id, data) =>
		lxtBackend.post(`contributor/completions/${id}/dp`, data),

	rvSendCompletionResult: async (id, data) =>
		lxtBackend.post(`contributor/completions/${id}/rv`, data),

	sendComment: async (completionID, role, commentTxt) =>
		lxtBackend.post(
			`contributor/completions/${completionID}/comments?role=${role}`,
			{ text: commentTxt },
		),

	updateComment: async (commentID, updatedText) =>
		lxtBackend.patch(`contributor/comments/${commentID}`, {
			text: updatedText,
		}),

	deleteComment: async (commentID) =>
		lxtBackend.delete(`contributor/comments/${commentID}`),

	sendTimeIncrement: async (completionID, role, timeIncrement) =>
		lxtBackend.post(
			`contributor/completions/${completionID}/increase-handling-time`,
			{ secs: timeIncrement, type: role === 'dp' ? 1 : 2 },
		),

	initiateUploadSession: async (completionID, fileInfo) =>
		lxtBackend.post(
			`contributor/completions/${completionID}/initiate-upload-session`,
			fileInfo,
		),

	uploadRecordedAudioToBucket: async (
		uploadURL,
		recordedAudio,
		progressCallback,
	) => {
		const config = {
			onUploadProgress: progressCallback,
		};
		return lxtBackend.put(uploadURL, recordedAudio, config);
	},

	streamUploadFileStatus: async (fileId, onMessage, onError) => {
		const csrfToken =
			document.cookie
				.match('(^|;)\\s*' + 'XSRF-TOKEN' + '\\s*=\\s*([^;]+)')
				?.pop() || '';

		// Init EventSource with the URL and credentials
		const eventSource = new EventSource(
			`${config.lxt_backend}contributor/files/upload_status/${fileId}`,
			{ withCredentials: true },
		);

		eventSource.addEventListener('upload_progress', (event) => {
			onMessage(JSON.parse(event.data));
		})

		eventSource.onerror = (event) => {
			onError(event.error);
			eventSource.close();
		};

		return () => {
			eventSource.close();
		};
	},

	uploadFileToBucket: async (completion_id, fileInfo) =>
		lxtBackend.post(`contributor/completions/${completion_id}/files`, fileInfo),

	deleteFileInBucket: async (file_id) =>
		lxtBackend.post(`contributor/files/${file_id}/outdate`),

	getFileURL: async (file_id) =>
		lxtBackend.get(`contributor/files/${file_id}/url`),

	getAcceptedExtensions: async () =>
		lxtBackend.get(`contributor/files/extensions`),
};
