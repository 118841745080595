export const saveArrayBufferToIndexedDB = (arrayBuffer, mimeType)=> {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('MyDatabase', 2);

    // Handle database upgrades or creation
    request.onupgradeneeded = function(event) {
      const db = event.target.result;

      // Check if the object store exists, and create it if it doesn’t
      if (!db.objectStoreNames.contains('audioFiles')) {
        const objectStore = db.createObjectStore('audioFiles', { autoIncrement: true });
        objectStore.createIndex('mimeType', 'mimeType', { unique: false });
      };
    };

    request.onerror = function(event) {
      reject(`Error opening IndexedDB: ${event.target.error}`);
    };

    request.onsuccess = function(event) {
      const db = event.target.result;

      const transaction = db.transaction(['audioFiles'], 'readwrite');

      const objectStore = transaction.objectStore('audioFiles');

      const addRequest = objectStore.add({ arrayBuffer, mimeType });

      addRequest.onsuccess = function(event) {
        resolve(event.target.result); // Return the ID of the stored item
      };

      addRequest.onerror = function(event) {
        reject(`Error saving arrayBuffer to IndexedDB: ${event.target.error}`);
      };

      transaction.oncomplete = function(event) {
        db.close();
      };
    };
  });
};

let dbConnection = null;

export const openDatabase = () => {
  return new Promise((resolve, reject) => {
    if (dbConnection) {
      resolve(dbConnection);
      return;
    }

    const request = indexedDB.open('MyDatabase', 2);

    request.onerror = function(event) {
      reject(`Error opening IndexedDB: ${event.target.error}`);
    };

    request.onsuccess = function(event) {
      dbConnection = event.target.result;
      resolve(dbConnection);
    };

    request.onupgradeneeded = function(event) {
      const db = event.target.result;
      if (!db.objectStoreNames.contains('audioFiles')) {
        db.createObjectStore('audioFiles', { keyPath: 'id' });
      }
    };
  });
};


export const retrieveArrayBufferFromIndexedDB = async (id) => {
  try {
    const db = await openDatabase();

    return new Promise((resolve, reject) => {
      const transaction = db.transaction(['audioFiles'], 'readonly');
      const objectStore = transaction.objectStore('audioFiles');
      const getRequest = objectStore.get(id);

      getRequest.onsuccess = function(event) {
        if (event.target.result) {
          resolve(event.target.result);
        } else {
          reject(`ArrayBuffer with ID ${id} not found in IndexedDB`);
        }
      };

      getRequest.onerror = function(event) {
        reject(`Error retrieving ArrayBuffer from IndexedDB: ${event.target.error}`);
      };
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const closeDatabase = () => {
  if (dbConnection) {
    dbConnection.close();
    dbConnection = null;
  }
};

