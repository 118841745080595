import React from "react";
import ReactDOM from "react-dom";
import throttle from "lodash.throttle";
import { Slider, Row, Col, Select, Button, Progress, Space, Tooltip, message, InputNumber } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { SoundOutlined, ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";

import WaveSurfer from "wavesurfer.js";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions.esm"
import TimelinePlugin from "wavesurfer.js/dist/plugins/timeline.esm";
import HoverPlugin from "wavesurfer.js/dist/plugins/hover.esm";

import { retrieveArrayBufferFromIndexedDB, closeDatabase, openDatabase } from '../../../contexts/helpers/helpers';

import globalStyles from "../../styles/global.module.scss";
import { formatWaveformTimeCallback, waveformTimeInterval, waveformPrimaryLabelInterval, waveformSecondaryLabelInterval } from '../../utils/date';
// import InfoModal from "../Infomodal/Infomodal";
// import messages from "../../utils/messages";

import styles from "./Waveform.module.scss";


const INVALID_INPUT_TIME_MESSAGE = 'Invalid input time value. The format of the input time value is minutes:seconds.milli-seconds and the max values are 59 seconds and 999 milli-seconds';

export default class Waveform extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      src: this.props.src,
      pos: 0,
      colors: {
        waveColor: "#97A0AF",
        progressColor: "#52c41a",
      },
      zoom: 0,
      speed: 1,
      volume: 1,
      start: undefined,
      end: undefined,
      currentRegionID: undefined,

      displayInputs: false,
      waveformError: false,
      isTabletOrMobile: this.props.isTabletOrMobile
    };
  }

  /**
   * Handle to change zoom of wave
   */
  onChangeZoom = value => {
    this.setState({
      ...this.state,
      zoom: value,
    });

    this.wavesurfer.zoom(value);
  };

  onChangeVolume = value => {
    this.setState({
      ...this.state,
      volume: value,
    });

    this.wavesurfer.setVolume(value);
  };

  setRegionStart(startTime) {

    const ws = this.wavesurfer;
    const state = this.state;

    const startTimeInSeconds = typeof startTime === 'number' ? startTime : this.getTimeInSecondsFromString(startTime);
    if (startTimeInSeconds === null) {
      message.warning(INVALID_INPUT_TIME_MESSAGE, 5);
      return;
    }

    let validStart = 0;

    if (startTimeInSeconds >= state.end) {
      message.warning(`Start value: ${this.inputTimeFormatter(startTimeInSeconds)} can't be greater than or equal End Value: ${this.inputTimeFormatter(state.end)}`)
      validStart = state.start;
    }
    else if (startTimeInSeconds < 0) {
      message.warning(`Start value can't be less than 0`);
      validStart = state.start;
    }
    else {
      validStart = startTimeInSeconds;
    }

    this.setState({ ...state, start: validStart });
    ws.regions.list[state.currentRegionID].update({ start: validStart, end: state.end })
  }

  setRegionEnd(endTime) {

    const ws = this.wavesurfer;
    const state = this.state;

    const endTimeInSeconds = typeof endTime === 'number' ? endTime : this.getTimeInSecondsFromString(endTime);
    if (endTimeInSeconds === null) {
      message.warning(INVALID_INPUT_TIME_MESSAGE, 5);
      return;
    }

    let validEnd = 0;
    const audioEndTime = ws.getDuration();

    if (endTimeInSeconds <= state.start) {
      message.warning(`End value: ${this.inputTimeFormatter(endTimeInSeconds)} can't be less than or equal Start value: ${this.inputTimeFormatter(state.start)}`);
      validEnd = state.end;
    }
    else if (endTimeInSeconds > audioEndTime) {
      message.warning(`End value: ${this.inputTimeFormatter(endTimeInSeconds)} can't be greater than Audio End time: ${this.inputTimeFormatter(audioEndTime)}`);
      validEnd = audioEndTime;
    }
    else {
      validEnd = endTimeInSeconds;
    }

    this.setState({ ...state, end: validEnd });
    ws.regions.list[state.currentRegionID].update({ start: state.start, end: validEnd });
  }

  // Converts the string time value from "MM:SS.MSS" to numeric seconds like 4335.1243
  getTimeInSecondsFromString(timeString) {

    // Validating the input value.
    if (!timeString || typeof timeString !== 'string') return null;

    let mins = 0;
    let secs = 0;
    let ms = 0;
    let secAndMs = [];

    const timeValues = timeString.split(':');
    if (timeValues.length === 2) {
      mins = Number(timeValues[0]);
      secAndMs = timeValues[1].split('.');
      secs = Number(secAndMs[0]);
      // Since the user can skip writing the microseconds part, we need to test it is there first.
      if (secAndMs.length === 2)
        ms = Number(secAndMs[1]);
    }
    else {
      // The else means the user skipped writing the minutes portion and wrote something like 44.5 which is interpreted in the format of SS.MSS
      secAndMs = timeString.split('.');
      secs = Number(secAndMs[0]);
      // Since the user can skip writing the microseconds part, we need to test it is there first.
      if (secAndMs.length === 2)
        ms = Number(secAndMs[1]);
    }

    // Validating the extracted numbers are valid.
    if (isNaN(mins) || isNaN(secs) || isNaN(ms))
      return null;

    // Validating the limits of the extracted numbers.
    if (mins < 0 || secs < 0 || secs > 59 || ms < 0 || ms > 999)
      return null;

    // Handling the case when the user enteres for example MM:SS.5 and expects the 5 to be 500 not 005
    if (ms > 0 && secAndMs[1].length < 3)
      for (let i = secAndMs[1].length; i < 3; i++)
        ms *= 10;

    // console.log(mins, secs, ms);

    const seconds = mins * 60 + secs + ms / 1000;
    return seconds;
  }

  // Converts the numeric input time value in passed ex: 120.123 seconds to the string "MM:SS.MS"
  inputTimeFormatter(valueString) {
    const numValue = Number(valueString);

    const locale = 'en-US';
    const digits2params = { minimumIntegerDigits: 2, useGrouping: false };
    const digits3params = { minimumIntegerDigits: 3, useGrouping: false };

    const number = Math.trunc(numValue);
    const fraction = (numValue % 1).toFixed(3);

    const strMins = Math.trunc(number / 60).toLocaleString(locale, digits2params);
    const strSecs = (number % 60).toLocaleString(locale, digits2params);
    const strMs = (fraction * 1000).toLocaleString(locale, digits3params);

    // console.log(strMins, strSecs, strMs);

    const formattedValue = `${strMins}:${strSecs}.${strMs}`;
    return formattedValue;
  }

  // Handle to change speed of wave
  onChangeSpeed = value => {
    this.setState({
      ...this.state,
      speed: value,
    });

    this.wavesurfer.setPlaybackRate(value);
  };

  onZoomPlus = (ev, step = 10) => {
    let val = this.state.zoom;
    val = val + step;
    if (val > 700) val = 700;

    this.onChangeZoom(val);
    ev && ev.preventDefault();
    return false;
  };

  onZoomMinus = (ev, step = 10) => {
    let val = this.state.zoom;
    val = val - step;
    if (val < 0) val = 0;

    this.onChangeZoom(val);
    ev.preventDefault();
    return false;
  };

  onWheel = e => {
    if (e && !e.shiftKey) {
      return;
    } else if (e && e.shiftKey) {
      /**
       * Disable scrolling page
       */
      e.preventDefault();
    }

    const step = e.deltaY > 0 ? 5 : -5;
    // console.log(e.evt.deltaY);
    this.onZoomPlus(e, step);
  };

  async componentDidMount() {
    this.$el = ReactDOM.findDOMNode(this);

    this.$waveform = this.$el.querySelector("#wave");

    let wavesurferConfigure = {
      container: this.$waveform,
      waveColor: this.state.colors.waveColor,
      height: this.props.height,
      backend: "WebAudio",
      progressColor: this.state.colors.progressColor,
      splitChannels: false,
      autoCenter: false,

      //It was found that the header is not added for some unkown reason. This will be investigated more later when there is time.
      // xhr: {
      //   headers: [{ key: 'X-XSRF-TOKEN', value: this.getXsrfTokenValue() }]
      // }

    };

    const regions = RegionsPlugin.create()
    const timeLine =
      TimelinePlugin.create({
        container: "#timeline", // the element in which to place the timeline, or a CSS selector to find it
        formatTimeCallback: formatWaveformTimeCallback, // custom time format callback. (Function which receives number of seconds and returns formatted string)
        timeInterval: waveformTimeInterval, // number of intervals that records consists of. Usually it is equal to the duration in minutes. (Integer or function which receives pxPerSec value and returns value)
        primaryLabelInterval: waveformPrimaryLabelInterval, // number of primary time labels. (Integer or function which receives pxPerSec value and reurns value)
        secondaryLabelInterval: waveformSecondaryLabelInterval, // number of secondary time labels (Time labels between primary labels, integer or function which receives pxPerSec value and reurns value).
      });
    const hover = HoverPlugin.create({
      lineColor: '#ff0000',
      lineWidth: 2,
      labelBackground: '#555',
      labelColor: '#fff',
      labelSize: '11px',
    });

    if (this.props.regions) {
      wavesurferConfigure = {
        ...wavesurferConfigure,
        plugins: [regions,timeLine,hover],
      };
    }

    this.wavesurfer = WaveSurfer.create(wavesurferConfigure);

    this.wavesurfer.on("error", e => {
      const error = String(e.message || e || "");
      // const url = this.props.src;

      // just general error message
      // let body = messages.ERR_LOADING_AUDIO({ attr: this.props.dataField, error, url });

      // "Failed to fetch" or HTTP error
      if (error?.includes("HTTP") || error?.includes("fetch")) {
        this.wavesurfer.hadNetworkError = true;

        // body = messages.ERR_LOADING_HTTP({ attr: this.props.dataField, error, url });
      } else if (typeof e === "string" && e.includes("media element")) {
        // obviously audio cannot be parsed if it was not loaded successfully
        // but WS can generate such error even after network errors, so skip it
        if (this.wavesurfer.hadNetworkError) return;
        // "Error loading media element"
        // body = "Error while processing audio. Check media format and availability.";
      }

      this.setState({ ...this.state, waveformError: true });
      console.log('Waveform Fetching Error!!!');
      // InfoModal.error(body, "Wow!");
    });


    try {
    // Load data for waveform from IndexDB
    await openDatabase();

    // Validate and parse this.props.src to an integer as it's a must to be able to read it from IndexedDB
    const srcId = parseInt(this.props.src);
    if (isNaN(srcId)) {
        throw new Error("Invalid source ID provided.");
    }

    // Retrieve array buffer and mime type from IndexedDB
    const { arrayBuffer: retrievedArrayBuffer, mimeType: retrievedMimeType } = await retrieveArrayBufferFromIndexedDB(srcId);
    const blob = new Blob([retrievedArrayBuffer], { type: retrievedMimeType });

    // Load blob into WaveSurfer
    await this.wavesurfer.loadBlob(blob);
} catch (error) {
    console.error("An error occurred while loading waveform data:", error);
} finally {
    // Ensure the database is closed
    closeDatabase();
}

    /**
     * Speed of waveform
     */
    this.wavesurfer.setPlaybackRate(this.state.speed);

    const self = this;

    if (this.props.regions) {
      /**
       * Mouse enter on region
       */
      this.wavesurfer.on("region-mouseenter", reg => {
        reg._region.onMouseOver();
      });

      /**
       * Mouse leave on region
       */
      this.wavesurfer.on("region-mouseleave", reg => {
        reg._region.onMouseLeave();
      });

      this.wavesurfer.on("region-removed", () => {
        this.setState({
          ...this.state,
          displayInputs: false
        })
      });

      /**
       * Add region to wave
       */
      this.wavesurfer.on("region-created", reg => {
        const region = self.props.addRegion(reg);
        if (!region) return;

        reg._region = region;
        reg.color = region.selectedregionbg;

        reg.on("click", (e) => {
          if (reg._region.id !== this.state.currentRegionID) {

            if (!reg._region.selected) //checking if the region is selected or not, to avoid unselecting it.
              region.onClick(self.wavesurfer)

            //setting input fields onClick on the region
            this.setState({
              ...this.state,
              start: reg.start,
              end: reg.end,
              currentRegionID: reg._region.id,
              displayInputs: true
            });
          }
        });

        //updating results and frontend region
        reg.on("update-end", () => {
          reg.start = Number(reg.start.toFixed(3));
          reg.end = Number(reg.end.toFixed(3));
          //fixing -ve issue in results
          if (reg.start < 0) {
            reg.start = 0;

          } else if (reg.start === -0) {
            reg.start = 0;
          }
          region.onUpdateEnd(self.wavesurfer);

          //Selecting the region after leaving dragging it, and changing its position.
          if (!this.state.currentRegionID)
            reg._region.onClickRegion()

          //setting input fields on creation of region using dragging.
          this.setState({
            ...this.state,
            start: reg.start,
            end: reg.end,
            currentRegionID: reg._region.id,
            displayInputs: true
          });
        });

        reg.on("dblclick", e => {
          window.setTimeout(function () {
            reg.play();
          }, 0);
        });

        reg.on("out", () => { });

        reg.on("update", () => {
          //to allow the update of values on the [regions-menu]
          reg._region.setEnd(reg.end)
          reg._region.setStart(reg.start)

          if (!reg._region.selected) {
            //Hide inputs on de-selection of regions
            this.setState({
              ...this.state,
              currentRegionID: undefined,
              displayInputs: false
            })

          } else {
            //displaying input fields on pressing on a region from the right
            this.setState({
              ...this.state,
              start: reg.start,
              end: reg.end,
              displayInputs: true,
              currentRegionID: reg._region.id,
            })
          }
        });

        //clicking on the region to make it selected after creation immediatly
        reg._region.onClickRegion();

        //setting input fields on creation of region using Add-region button.
        this.setState({
          ...this.state,
          start: reg.start,
          end: reg.end,
          currentRegionID: reg._region.id,
          displayInputs: true
        });
      });
    }

    /**
     * Handler of slider
     */
    const slider = document.querySelector("#slider");

    if (slider) {
      slider.oninput = function () {
        self.wavesurfer.zoom(Number(this.value));
      };
    }

    this.wavesurfer.on("ready", () => {
      self.props.onCreate(this.wavesurfer);
      this.wavesurfer.container.onwheel = throttle(this.onWheel, 100);
    });

    /**
     * Pause trigger of audio
     */
    this.wavesurfer.on("pause", self.props.handlePlay);

    /**
     * Play trigger of audio
     */
    this.wavesurfer.on("play", self.props.handlePlay);

    if (this.props.regions) {
      this.props.onLoad(this.wavesurfer);
    }
  }

  render() {
    const self = this;
    const speeds = ["0.5", "0.75", "1.0", "1.25", "1.5", "2.0"];

    return (
      <div>
        <div id="wave" className={styles.wave} />

        <div id="timeline" />

        {this.props.zoom && (
          <div>
            <Row gutter={16} style={{ marginTop: "1em" }}>
              <Col flex={12} style={{ textAlign: "right", marginTop: "6px" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ marginTop: "6px", marginRight: "5px" }}>
                    <ZoomOutOutlined onClick={this.onZoomMinus} className={globalStyles.link} />
                  </div>
                  <div style={{ width: "100%" }}>
                    <Slider
                      min={0}
                      step={10}
                      max={500}
                      value={typeof this.state.zoom === "number" ? this.state.zoom : 0}
                      onChange={value => {
                        this.onChangeZoom(value);
                      }}
                    />
                  </div>
                  <div style={{ marginTop: "6px", marginLeft: "5px" }}>
                    <ZoomInOutlined onClick={this.onZoomPlus} className={globalStyles.link} />
                  </div>
                </div>
              </Col>
              <Col flex={3}>
                {this.props.volume && (
                  <div style={{ display: "flex", marginTop: "6.5px" }}>
                    <div style={{ width: "100%" }}>
                      <Slider
                        min={0}
                        max={1}
                        step={0.1}
                        value={typeof this.state.volume === "number" ? this.state.volume : 1}
                        onChange={value => {
                          this.onChangeVolume(value);
                        }}
                      />
                    </div>
                    <div style={{ marginLeft: "10px", marginTop: "5px" }}>
                      <SoundOutlined />
                    </div>
                  </div>
                )}
              </Col>
              <Col flex={1} style={{ marginTop: "6px" }}>
                {this.props.speed && (
                  <Select
                    placeholder="Speed"
                    style={{ width: "100%" }}
                    defaultValue={this.state.speed}
                    onChange={self.onChangeSpeed}
                  >
                    {speeds.map(speed => (
                      <Select.Option value={+speed} key={speed}>
                        Speed {speed}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Col>
            </Row>

            <Row>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                width: "100%",
                marginTop: "15px",
                padding: '10px',
                border: '1px solid #eee',
                borderRadius: '5px'
              }}>
                <Space>
                  {/**Add Region button */}
                  <Tooltip placement="topLeft" title="Select label to create a region">
                    <Button shape="circle" icon={<PlusOutlined />} size='middle' type="primary" style={{ marginRight: '15px' }}
                      onClick={() => {
                        if ((this.wavesurfer.getCurrentTime() + 1) <= this.wavesurfer.getDuration()) {
                          this.wavesurfer.addRegion({
                            start: Number(this.wavesurfer.getCurrentTime().toFixed(3)),
                            end: Number(this.wavesurfer.getCurrentTime() + 1).toFixed(3)
                          })
                        }
                      }} />
                  </Tooltip>

                  {this.state.displayInputs &&
                    <Space direction={this.state.isTabletOrMobile ? 'vertical' : 'horizontal'}>
                      {/**Start input field*/}
                      <div>
                        <label >Start: </label>
                        <InputNumber
                          //style={{ width: 130 }}
                          min={0}
                          value={this.state.start}
                          formatter={value => this.inputTimeFormatter(value)}
                          onPressEnter={e => e.target.blur()}
                          onStep={value => this.setRegionStart(value)}
                          onBlur={e => this.setRegionStart(e.target.value)}
                        />
                      </div>

                      {/**End input field*/}
                      <div>
                        <label>End: </label>
                        <InputNumber
                          //style={{ width: 130 }}
                          min={0}
                          value={this.state.end}
                          formatter={value => this.inputTimeFormatter(value)}
                          onPressEnter={e => e.target.blur()}
                          onStep={value => this.setRegionEnd(value)}
                          onBlur={e => this.setRegionEnd(e.target.value)}
                        />
                      </div>
                    </Space>}
                </Space>
              </div>
            </Row>
          </div>
        )}

        {/* The error message for the failure of fetchingt the waveform. */}
        {this.state.waveformError && <div>Unable to load the waveform for this audio file!</div>}

      </div>
    );
  }
}
