import React, { useCallback } from 'react';
import { get } from 'lodash-es';
import { useHistory } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import { useSelector } from 'react-redux';
import { Button, Card, List, Menu } from 'antd';
import styled from 'styled-components';
import {
	CheckCircleTwoTone,
	ClockCircleOutlined,
	ExclamationCircleTwoTone,
	LoadingOutlined,
} from '@ant-design/icons';

import TextStrong from '../TextHiglighter';
import { NOT_AVAIALBLE } from '../LookUpTables';
import { COMPLETION_DP_PATH_ID } from '../../../routes';
import { UPLOAD_STATES } from '../../../Constants';

const noop = () => null;

const uploadStateAction = {
	[UPLOAD_STATES.Uploading]: [<LoadingOutlined title="Uploading..." style={{ fontSize: '24px' }} />],
	[UPLOAD_STATES.Pending]: [<ClockCircleOutlined title="Upload Pending" style={{ fontSize: '24px' }} />],
	[UPLOAD_STATES.Failed]: [<ExclamationCircleTwoTone title="Error..." twoToneColor="#FFA500" style={{ fontSize: '25px' }} />],
	[UPLOAD_STATES.Finished]: [<CheckCircleTwoTone title="Upload Complete" twoToneColor="#52c41a" style={{ fontSize: '24px' }} />],
	[UPLOAD_STATES.Preparing]: [<LoadingOutlined title="Preparing..." style={{ fontSize: '24px' }} />],
	[UPLOAD_STATES.Validating]: [<LoadingOutlined title="Validating..." style={{ fontSize: '24px' }} />],
	[UPLOAD_STATES.Invalid]: [<ExclamationCircleTwoTone title="Invalid" twoToneColor="#FF2500" style={{ fontSize: '25px' }} />],
}

const uploadStateMessage = {
	[UPLOAD_STATES.Uploading]: () => `Uploading...`,
	[UPLOAD_STATES.Finished]: () => `Upload complete.`,
	[UPLOAD_STATES.Failed]: (item) => ['Upload failed', item?.error?.error?.response?.data?.detail].filter(Boolean).join(', '),
	[UPLOAD_STATES.Preparing]: () => `Preparing...`,
	[UPLOAD_STATES.Validating]: () => `Validating...`,
	[UPLOAD_STATES.Invalid]: (item) => [...item?.errors].join('\n- '),
}

const ListItemMeta = styled(List.Item.Meta)`
	& .ant-list-item-meta-title {
		max-width: 180px;
	}
`

const UploadCardContent = ({ files, completionID, projectID }) => {
	const history = useHistory();

	const hasError = Object.values(files)
		.reduce((accum, { status, errors }) => (accum || (status === UPLOAD_STATES.Failed || errors?.length > 0 )), false)

	const handleOnOpenCompletion = useCallback(() => {
		history.push(formatRoute(COMPLETION_DP_PATH_ID, { projectId: projectID, compId: completionID }))
	}, [completionID, projectID, history]
	)

	const header = hasError
		? (
			<>
				Error with your uploads for completion <TextStrong text={completionID ? completionID : NOT_AVAIALBLE} />
				<br /><br />
				<Button
						type='primary'
						size='small'
						onClick={handleOnOpenCompletion}
					>
						Fix Completion
					</Button>
				<br /><br />
			</>
		)
		: (
			<>
				Your uploads for completion <TextStrong text={completionID ? completionID : NOT_AVAIALBLE} />
			</>
		)

	return (
		<div>
			{header}
			<List
				itemLayout="horizontal"
				dataSource={Object.values(files)}
				renderItem={(file) => (
					<List.Item
						actions={get(uploadStateAction, file.status, uploadStateAction.Pending)}
					>
						<ListItemMeta
							title={file.filename || file.name}
							description={get(uploadStateMessage, file.status, noop)(file)}
						/>
					</List.Item>
				)}
			/>
		</div>
	)
}

export const UploadFileNotificationItem = ({ uploadCompletion }) => {
	if (!uploadCompletion?.files) {
		return null;
	}


	return (
		<Card
			size='small'
			bodyStyle={{ overflow: 'auto', whiteSpace: 'pre-line', fontSize: '0.8rem' }}
		>

			<UploadCardContent
				{...uploadCompletion}
			/>
		</Card>
	)
}

/**
 *
 * @param state
 * @returns {*&{pendingUploadTaskList: unknown[], activeUploadFileCount: number}}
 */
const uploadFilesSelector = (state) => {
	const { upload } = state

	const allUploadTaskList = Object.entries(upload.toUploadCompletionFiles)
		.map(([completionID, completionData]) => {
			const hasIncompleteUpload = Object.values(completionData?.files ?? [])?.reduce((accum, file) => {
				return accum || file?.originFileObj !== undefined
			}, false)

			if (!hasIncompleteUpload || !completionData?.files) {
				return null;
			}

			return { ...completionData, completionID }
		})
		.filter(Boolean)

	const pendingUploadTaskList = Object.entries(upload.toUploadCompletionFiles)
		.map(([completionID, completionData]) => {
			const hasIncompleteUpload = Object.values(completionData?.files ?? [])?.reduce((accum, file) => {
				return accum || file?.status !== 'Finished'
			}, false)

			if (!hasIncompleteUpload || !completionData?.files) {
				return null;
			}

			return { ...completionData, completionID }
		})
		.filter(Boolean)

	const activeUploadFileCount = (pendingUploadTaskList || {}).reduce((count, task) => {
		return count + Object.values(task.files || {})
			.reduce((cnt, taskFile) => {
				if (taskFile.status !== 'Finished') {
					return cnt + 1;
				}

				return cnt;
			}, 0)
	}, 0)

	return {
		...upload,
		allUploadTaskList,
		pendingUploadTaskList,
		activeUploadFileCount,
		activeUploadTaskCount: pendingUploadTaskList.length,
	}
}

export const useUploadList = () => {
	return useSelector(uploadFilesSelector);
}

export const uploadFileNotificationRender = (pendingUploadTaskList) => {
	if (!pendingUploadTaskList) {
		return null;
	}

	return pendingUploadTaskList.map((uploadCompletion) => uploadCompletion?.files
		? (
			<Menu.Item key={uploadCompletion?.completionID} onClick={e => e.preventDefault()}>
				<UploadFileNotificationItem uploadCompletion={uploadCompletion} />
			</Menu.Item>
		) : null
	)
}
