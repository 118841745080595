import React, { useState, useCallback } from 'react';

const VideoViewer = ({ file, url, togglePlay }) => {
	const [videoURL, setVideoURL] = useState('');

	const getFileURL = useCallback((file) => {
		const url = new Promise((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(file.originFileObj);
			reader.onload = () => resolve(reader.result);
		});

		setVideoURL(url);
	}, []);

	if (file && !url) {
		getFileURL(file);
	}

	return (
		<video
			src={url || videoURL}
			controls
			onPlay={togglePlay}
			onPause={togglePlay}
			style={{ width: '100%' }}
			controlsList="nofullscreen nodownload"
		/>
	);
};

export default VideoViewer;
