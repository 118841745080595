import { useEffect, useRef } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { withRouter, useLocation, useRouteMatch } from 'react-router-dom';
import {
	get,
	set,
	first,
	values,
	fromPairs,
	entries,
	camelCase,
	upperFirst,
} from 'lodash-es';

import * as routes from './routes';

const APP_ROUTES = values(routes);

const REACT_APP_GIT_DATE = process.env.REACT_APP_GIT_DATE;
const REACT_APP_GIT_HASH = process.env.REACT_APP_GIT_HASH;
const REACT_APP_VERSION = process.env.REACT_APP_VERSION;
const REACT_APP_APP_ENV = process.env.REACT_APP_APP_ENV;

const ddBaseConfig = {
	site: 'datadoghq.com',
	service: 'ldp:legacy',
	silentMultipleInit: true,
	version: REACT_APP_VERSION,
	env: REACT_APP_APP_ENV ?? first(window.location.host.split('.')),
};

//region ***** DD Logs *****

datadogLogs.init({
	...ddBaseConfig,

	sampleRate: 100,
	forwardReports: 'all',
	forwardConsoleLogs: 'all',
	forwardErrorsToLogs: true,
	clientToken: 'pub91d65061c2888d62f3c26baf32448acd',
});

//endregion

//region ***** DD RUM *****

datadogRum.init({
	...ddBaseConfig,

	trackLongTasks: true,
	trackResources: true,
	trackFrustrations: true,
	trackUserInteractions: true,

	defaultPrivacyLevel: 'mask-user-input',
	actionNameAttribute: 'data-action-name',

	sessionSampleRate: 100,
	sessionReplaySampleRate: 100,

	traceSampleRate: 100,
	allowedTracingUrls: ['https://api.lxt.ai', /https:\/\/.*\.lxt\.ai/],

	storeContextsAcrossPages: true,
	enableExperimentalFeatures: ['feature_flags'],

	clientToken: 'pub91d65061c2888d62f3c26baf32448acd',
	applicationId: '73327b11-abd7-41b8-8ebd-cb39add85687',
});

//endregion

//region ***** DD Set Global Context Property *****

localStorage.setItem('ldp:version', `${REACT_APP_VERSION}`);
localStorage.setItem('ldp:stg-moniker', `${process.env.REACT_APP_ENV}`);
localStorage.setItem(
	'ldp:build',
	JSON.stringify({
		m: process.env.REACT_APP_GIT_DIRTY,
		t: process.env.REACT_APP_GIT_TAG,
		b: process.env.REACT_APP_GIT_BRANCH,
		d: process.env.REACT_APP_GIT_DATE,
		l: process.env.REACT_APP_GIT_HASH,
		h: process.env.REACT_APP_GIT_HASH_SHORT,
	}),
);

datadogRum.setGlobalContextProperty('EVN_CLASS', process.env.REACT_APP_ENV);
datadogLogs.setGlobalContextProperty('EVN_CLASS', process.env.REACT_APP_ENV);

datadogRum.setGlobalContextProperty('VERSION', REACT_APP_VERSION);
datadogLogs.setGlobalContextProperty('VERSION', REACT_APP_VERSION);

datadogRum.setGlobalContextProperty(
	'CALVERSION',
	process.env.REACT_APP_VERSION_CALENDAR,
);
datadogLogs.setGlobalContextProperty(
	'CALVERSION',
	process.env.REACT_APP_VERSION_CALENDAR,
);

datadogRum.setGlobalContextProperty('GIT_HASH', REACT_APP_GIT_HASH);
datadogLogs.setGlobalContextProperty('GIT_HASH', REACT_APP_GIT_HASH);

//endregion

//region ***** DD Start Session Replay Recording *****

if (parseJSON(process.env.REACT_APP_DATADOG_SESSION_REPLAY) !== false) {
	datadogRum.startSessionReplayRecording();
}

//endregion

//region ***** DD Rum View Instrumentation *****

function getRouteName(pathName) {
	const routeEntries = entries({ ...routes });
	console.log('getRouteName', routeEntries);

	for (const [key, path] of routeEntries) {
		const isPathMatch = pathName === path;
		console.log('getRouteName', { isPathMatch });
		if (isPathMatch) {
			return upperFirst(camelCase(key.replace(/_PATH/gi, '')));
		}
	}
	return pathName;
}

function nameParams(params) {
	return fromPairs(
		entries(params).map(([k, v]) => {
			return [camelCase(k), v];
		}),
	);
}

export function useDDRumViewInstrumentation() {
	const logger = useRef(datadogLogs.createLogger('View'));
	const location = useLocation();
	const match = useRouteMatch(APP_ROUTES);

	useEffect(() => {
		const routeName = getRouteName(match?.path);
		const info = {
			match,
			name: routeName,
			routesList: APP_ROUTES,
			pathname: location.pathname,
		};

		console.log('DDRumViewInstrumentation', 'startView', info);

		datadogRum.startView(info);

		const viewDetail = {
			href: window.location.href,
			pathname: location.pathname,
			...nameParams(match?.params ?? {}),
		};

		datadogRum.addAction(`view::${routeName}`, viewDetail);
		logger.current.info(`view::${routeName}`, viewDetail);
	}, [location.pathname, match]);
}

export const DDRumViewInstrumentation = withRouter(() => {
	useDDRumViewInstrumentation();
	return null;
});

//endregion

//region ***** utils *****
/**
 *
 * @param {string} text
 * @returns {*}
 */
function parseJSON(text) {
	try
	{
		if(text)
			return JSON.parse(text);
	} catch (e) {
		console.error('((QUITE))', e);
		return text;
	}
}

/**
 * @param {string} path
 * @returns {*}
 */
function getInternalContext(path) {
	return get(datadogLogs.getInternalContext(), path);
}

/**
 * @returns {string}
 */
function getSessionId() {
	return getInternalContext('session_id');
}

/**
 * @param {string} name
 */
function getEnv(name) {
	const _APP_ENV_ = {
		GIT_DATE: process.env.GIT_DATE,
		GIT_HASH: process.env.GIT_HASH,
		VERSION: process.env.VERSION,
	};

	return get(_APP_ENV_, name);
}

//endregion

//region ***** Debugging *****

set(window, '__ldp.reporter.getSessionId', getSessionId);

set(window, '__ldp.reporter.getEnv', getEnv);

if (process.env.NODE_ENV === 'development') {
	window._APP_ENV_ = JSON.parse(JSON.stringify(process.env));

	window._DEF_ENV_ = {
		GIT_DATE: REACT_APP_GIT_DATE,
		GIT_HASH: REACT_APP_GIT_HASH,
		VERSION: REACT_APP_VERSION,
		APP_ENV: REACT_APP_APP_ENV,
	};

	window.__DDLogs__ = datadogLogs;
	window.__DDRUM__ = datadogRum;
}

//endregion
