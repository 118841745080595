import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Space, Card } from "antd";
import { LoginOutlined } from "@ant-design/icons";

import Content from "../../AntdWrappers/Content/Content";
import TwoFactorAuthVerification from '../TwoFactorAuthVerification';
import TwoFactorAuthPhoneNumber from '../TwoFactorAuthPhoneNumber';
import ErrorAlert from '../../Error/ErrorAlert';
import LoginWithEmailPassword from "./LoginWithEmailPassword";
import LxtLoadingLogo from "../../Common/LxtLoadingLogo";
import LoginWithEmailPasswordMobile from "./LoginWithEmailPasswordMobile";
import { useMediaQuery } from 'react-responsive'

import roles from "../../../model/roles";
import { useAuthContext } from '../../../contexts/AuthContext';
import { CONTRIBUTOR_PROJECTS_PATH, PM_PROJECTS_PATH, ROOT_PATH } from "../../../routes";
import "../Auth.scss"


const CardTitle = ({ icon, text }) => <Space className="card-title-style">{icon} {text}</Space>

const Login = ({ location }) => {

  const isTabletOrMobile = useMediaQuery({ maxWidth: 480 })

  const {
    user,
    isLoading,
    twoFactorAuthRequired,
    twoFactorAuthActivationRequired,
    secondFactorPhoneNumber,
    RECAPTCHA_VERIFIER_ID,
    isSendingSms,

    signInByEmailAndPassword,

    sendSmsVerificationCode,
    verifySmsCode,
    activateUserPhoneNumber,
    verifyUserPhoneNumber,
    quit2FA,

    initRecaptcha,
    clearRecaptcha,

    error,
    clearError
  } = useAuthContext();

  const [state, setState] = useState({
    phoneNumber: '',
    displayVerifyDeviceView: false,
  });

  useEffect(() => {
    initRecaptcha();
    return () => {
      clearError();
      clearRecaptcha();
    }
    //eslint-disable-next-line
  }, []);

  const cardStyle = {
    width: isTabletOrMobile ? '100%' : '450px',
    marginBottom: '20px'
  }


  const getRedirectPath = role => {
    if (location.state && location.state.referrer) {
      return location.state.referrer;
    }
    let to;
    switch (role) {
      case roles.CONTRIBUTOR:
        to = CONTRIBUTOR_PROJECTS_PATH;
        break;
      case roles.PM:
        to = PM_PROJECTS_PATH;
        break;
      default:
        to = ROOT_PATH;
    }
    return to;
  };



  // Handles sign in using email and passowrd.
  const handleSignIn = (email, password) => {
    signInByEmailAndPassword(email, password);
  }

  // Handles quitting the operation of 2FA sign in or 2FA enrollment to enable the user to go
  // back to the sign in page and sign in using a different account.
  const handleGoBackToSignIn = () => {
    setState(ps => ({
      ...ps,
      phoneNumber: '',
      displayVerifyDeviceView: false
    }));
    quit2FA();
  }

  // 2FA-SignIn: Send the verification code to the user's registered phone number.
  const handle2FALoginResendCode = () => {
    sendSmsVerificationCode();
  }

  // 2FA-SignIn: Verify the code entered to be the one sent in the sms.
  const handle2FALoginVerify = (verificationCode) => {
    verifySmsCode(verificationCode);
  }

  // 2FA-Enrollment: Verify enetered phone number by sending and sms.
  const handle2FAActivationSubmitPhoneNumber = async (phoneNumber = state.phoneNumber) => {

    if (phoneNumber) {

      // Keeping the phone number in the state for later trials to resend the code.
      setState(ps => ({ ...ps, phoneNumber }));

      // Sending the verification sms to the user to verify the entered phone number.
      activateUserPhoneNumber(phoneNumber)
        .then(() => setState(ps => ({ ...ps, displayVerifyDeviceView: true })))
        .catch(e => {
          // The recaptcha isn't verified or the activation of the phone number
          // has failed and reported in the error of the context, so we do nothing.
        });

    }
    else {
      console.log("Phone number is not available!");
    }
  }

  // 2FA-Enrollment: Verify the code entered to be the one sent in the sms.
  const handle2FAActivationVerifyPhoneNumber = (verificationCode) => {
    verifyUserPhoneNumber(verificationCode);
  }

  let twoFactorAuth = twoFactorAuthRequired || twoFactorAuthActivationRequired;

  const hasUser = Boolean(user?.role);
  const noUser = !hasUser

  return (
    <Content alignVertically>

      {/* Redirecting the user if he is logged in */}
      {hasUser && <Redirect to={getRedirectPath(user.role)} />}

      {/* Loading Logo */}
      {isLoading && <LxtLoadingLogo text='Signing In...' />}

      {/* Components for sign in. */}
      {noUser && !isLoading && !twoFactorAuth &&
        <Space align='center' direction="vertical">

          {/* Signing in with email and passoword */}
          {!isTabletOrMobile && <Card
            style={cardStyle}
            title={<CardTitle text='Please Sign In' icon={<LoginOutlined />} />}>
            <LoginWithEmailPassword signInByEmailAndPassword={handleSignIn} />
          </Card>}

          {/**Mobile Signin with email and password */}
          {isTabletOrMobile && <LoginWithEmailPasswordMobile signInByEmailAndPassword={handleSignIn} />}

          <ErrorAlert error={error} />
        </Space>
      }

      {/* The second factor authentication component. */}
      {noUser && !isLoading && twoFactorAuthRequired && !twoFactorAuthActivationRequired &&
        <Space align='center' direction="vertical">
          <Card
            loading={isSendingSms}
            style={cardStyle}
            title={<CardTitle text='Verification Code' />}>
            <TwoFactorAuthVerification
              resendCode={handle2FALoginResendCode}
              verifyCode={handle2FALoginVerify}
              goBackToSignIn={handleGoBackToSignIn}
              phoneNumber={secondFactorPhoneNumber}
            />
          </Card>

          <ErrorAlert error={error} />
        </Space>
      }

      {/* --------------------------------2FA Activation----------------------------------- */}
      {/* The second factor authentication activation enter phone number component. */}
      {noUser && !isLoading && !twoFactorAuthRequired && twoFactorAuthActivationRequired && !state.displayVerifyDeviceView &&
        <Space align='center' direction="vertical">
          <Card
            style={cardStyle}
            title={<CardTitle text='Second Authentication Factor Is Required!' />}>
            <TwoFactorAuthPhoneNumber
              submitPhoneNumber={handle2FAActivationSubmitPhoneNumber}
              goBackToSignIn={handleGoBackToSignIn}
            />
          </Card>

          <ErrorAlert error={error} />
        </Space>
      }

      {/* The second factor authentication activation verify device component. */}
      {noUser && !isLoading && !twoFactorAuthRequired && twoFactorAuthActivationRequired && state.displayVerifyDeviceView &&
        <Space align='center' direction="vertical">
          <Card
            loading={isSendingSms}
            style={cardStyle}
            title={<CardTitle text='Device Verification' />}>
            <TwoFactorAuthVerification
              resendCode={handle2FAActivationSubmitPhoneNumber}
              verifyCode={handle2FAActivationVerifyPhoneNumber}
              goBackToSignIn={handleGoBackToSignIn}
            />
          </Card>

          <ErrorAlert error={error} />
        </Space>
      }

      {/* The recaptcha verifier container */}
      <div id={RECAPTCHA_VERIFIER_ID}></div>

    </Content>
  )
};

export { Login as default, CardTitle };
