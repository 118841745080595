import { message } from 'antd';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { isFunction, pick } from 'lodash-es';
import { createContext, useContext, useState, useCallback } from 'react';

import firebase from '../Firebase';
import authApi from '../api/auth';
import usersApi from '../api/users';
import { lxtBackend } from '../api/utils';
import { formatError } from './ResponseErrorFormatter';
import { featureToggle } from '../lib/featureToggles';

const AuthContext = createContext();

const defaultSuccess = { isSuccess: false, message: '' };

const VITALITY_CHECK_INTERVAL = 200; //ms
const VITALITY_SLEEP_THRESHOLD = 60 * 1000; //ms

let _xsrfRefreshFailure = false; // The flag used to indicate the failure to refresh the xsrf token.
let _previousTime = Date.now(); // The time used to check

const RECAPTCHA_VERIFIER_ID = 'google-recaptcha-verifier-id';
const ERR_CODE_SECOND_FACTOR_REQUIRED = 'auth/multi-factor-auth-required';
const ERR_CODE_SECOND_FACTOR_RESET_RECENT_LOGIN_REQUIRED =
	'auth/requires-recent-login';
const PHONE_AUTH_FACTOR_INDEX = 0;

const AuthContextProvider = ({ children }) => {
	const [state, setState] = useState({
		user: null,
		secondFactorPhoneNumber: null,
		isLoading: false,
		isRefreshingCookies: false,
		error: null,
		success: defaultSuccess,

		// When this flag is set to true, then the user should be redirected to the 2FA page to enter the code sent to him.
		twoFactorAuthRequired: false,

		// When this flag is set, it means the user has to activate the 2FA before he can sign in.
		twoFactorAuthActivationRequired: false,

		// Set to true when the firebase operation requires a recent login.
		recentLoginRequired: false,

		// Set to true when the sms is being sent.
		isSendingSms: false,
	});

	const updateState = useCallback(
		(stateUpdate) => {
			setState((ps) => {
				let updatedState;

				if (isFunction(stateUpdate)) {
					updatedState = stateUpdate(ps);
				} else {
					updatedState = Object.assign({}, ps, updateState);
				}

				const sessionUser = pick(
					updatedState.user,
					'id',
					'role',
					'email',
					'firstName',
					'lastName',
					'pendingEmail',
				);

				sessionUser.name = `${sessionUser.firstName} ${sessionUser.lastName}`;

				datadogLogs.setUser(sessionUser);
				datadogRum.setUser(sessionUser);

				return updatedState;
			});
		},
		[setState],
	);

	// The auxilary state which contains the different objects used in google identity two factor authentication.
	const [gidState, setGidState] = useState({
		recaptchaVerifier: null,
		resolver: null,
		verificationId: null,
	});

	// Adding the interceptor of axios to log user out when an unauthorized backend call is done.
	const initializeAuth = () => {
		return new Promise((resolve) => {
			//Initializing LXT backend http handlers with interceptors.
			lxtBackend.interceptors.response.use(
				(response) => response,
				(error) => errorInterceptor(error),
			);

			//Starting the process of vitality check.
			setTimeout(xsrfVitalityCheck, VITALITY_CHECK_INTERVAL);

			//Setting the presistence mode to none to flush user data once the tab is closed or refreshed.
			firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);

			resolve();
		});
	};

	const errorInterceptor = async (error) => {
		const originalRequest = error.config;
		if (
			error.response &&
			error.response.status === 401 &&
			!originalRequest._retry
		) {
			try {
				updateState((ps) => ({ ...ps, isRefreshingCookies: true }));

				await authApi.refresh();

				updateState((ps) => ({ ...ps, isRefreshingCookies: false }));
				originalRequest._retry = true;
				return lxtBackend(originalRequest);
			} catch (e) {
				updateState((ps) => ({ ...ps, isRefreshingCookies: false }));
				//Tried to refresh and failed, so log user out immediately.
				logout();
				return Promise.reject(error);
			}
		} else if (
			error.response &&
			error.response.status === 401 &&
			originalRequest._retry
		) {
			//This clause covers the case when the refresh call is successfull and the original request is
			//retried then the original request, may it be the reason, returns 401 again.
			logout();

			const errorResponseMsg = error.response.data.detail;
			message.warning(`${errorResponseMsg}, You will be logged out!`);

			return Promise.reject(error);
		} else {
			return Promise.reject(error);
		}
	};

	const refreshXsrfToken = async (withLoading = false) => {
		return new Promise((resolve) => {
			//Setting the isLoading flag if required.
			if (withLoading) updateState((ps) => ({ ...ps, isLoading: true }));

			authApi
				.refreshXsrf()
				.then(() => {
					//Clearing the failure flag (if it was set) because the xsrf token was refreshed successfully.
					_xsrfRefreshFailure = false;

					//Resetting the isLoading flag back again to false if it was set to true.
					if (withLoading) updateState((ps) => ({ ...ps, isLoading: false }));

					resolve();
				})
				.catch((e) => {
					//The only case for the refresh of the xsrf token to fail, is when the network is down. And even if there is any other problem,
					//the website should keep trying to get the xsrf token. This process needs to be done before the user can do any action,
					//so the process is chosen to refresh every 200 ms and is handled by the vitality check function.
					_xsrfRefreshFailure = true;

					//Resetting the isLoading flag back again to false if it was set to true.
					if (withLoading) updateState((ps) => ({ ...ps, isLoading: false }));

					//There is no need to report any error here as there is nothing to display to the user upon the failure of the refresh process.
					resolve();
				});
		});
	};

	const xsrfVitalityCheck = async () => {
		//Checking whether the browser tab was put to sleep or not. If so, we need to refresh the xsrf token as the token may have
		//been expired in the sleep period.
		const currentTime = Date.now();
		const hasSlacked =
			currentTime - _previousTime >
			VITALITY_CHECK_INTERVAL + VITALITY_SLEEP_THRESHOLD;
		if (_xsrfRefreshFailure || hasSlacked) {
			await authApi
				.refreshXsrf()
				.then(() => (_xsrfRefreshFailure = false))
				.catch((e) => (_xsrfRefreshFailure = true));
		}

		//Updating the value of pervious time before going to sleep.
		_previousTime = Date.now();

		//setTimeout was perferred in favor of setInterval since we want to wait for the refreshXsrf to execute before starting to sleep again.
		setTimeout(xsrfVitalityCheck, VITALITY_CHECK_INTERVAL);
	};

	// Called at the beginning of the site's reload to get the user data if the cookies are available and still valid.
	const getLoggedUser = async () => {
		updateState((ps) => ({
			...ps,
			user: null,
			isLoading: true,
		}));

		try {
			const res = await usersApi.getUser();

			featureToggle.setUserAttributes({
				...res.data,
				loggedIn: true,
			});

			updateState((ps) => ({
				...ps,
				user: res.data,
				isLoading: false,
			}));
		} catch (e) {
			updateState((ps) => ({
				...ps,
				user: null,
				isLoading: false,
				//No need to report this error as this function is used to get the user data if tokens are still valid.
				error: null,
			}));
		}
	};

	// Sign in function using google caption.
	const signInByGoogle = async () => {
		updateState((ps) => ({
			...ps,
			isLoading: true,
			error: null,
		}));

		const provider = new firebase.auth.GoogleAuthProvider();
		firebase
			.auth()
			.signInWithPopup(provider)
			.then((googleRes) => {
				// Preparing the refresh and the access tokens for sign in process.
				const refreshToken = googleRes.user.refreshToken;
				const accessToken = googleRes.user.getIdToken();
				return Promise.all([refreshToken, accessToken]);
			})

			.then((userCredentials) => {
				//Fetching user information from the backend.
				return authApi.login({
					refreshToken: userCredentials[0],
					accessToken: userCredentials[1],
				});
			})

			.then((res) => {
				featureToggle.setUserAttributes({
					...res.data,
					loggedIn: true,
				});

				//Updating the state with data.
				updateState((ps) => ({
					...ps,
					user: res.data,
					isLoading: false,
					error: null,
				}));
			})

			//Handling the 2FA which is descriped by the documentation to be reported with an error.
			.catch((error) => handleSignInError(error));
	};

	// Sign in function using the email and password.
	const signInByEmailAndPassword = (email, password) => {
		//Signing in by email and password is only allowed for the external users. LXT users should sign in by google only.
		if (isLxtUser(email)) {
			updateState((ps) => ({
				...ps,
				error: formatError(
					{ message: 'LXT users are required to sign in by google.' },
					'Invalid Login Method!',
				),
			}));
			return;
		}

		gidState.recaptchaVerifier
			.verify()
			.then(() => {
				updateState((ps) => ({
					...ps,
					isLoading: true,
					error: null,
				}));
				return firebase.auth().signInWithEmailAndPassword(email, password);
			})
			.then(() => {
				// As the 2FA is mandatory for all users, when this case happenes, this means the second factor authentication is not activated,
				// so the user should be redirected to activate the 2FA by the UI. When the 2FA is enabled, the sign in function should report
				// and error that the second factor for authentication is requested per the google api documentation.
				updateState((ps) => ({
					...ps,
					isLoading: false,
					twoFactorAuthActivationRequired: true,
				}));
			})
			//Handling the 2FA which is descriped by the documentation to be reported with an error.
			.catch((error) => handleSignInError(error));
	};

	// Used by the two sign in functions to handle 2FA and regular errors.
	const handleSignInError = (error) => {
		// The case of the 2FA required error.
		if (error.code === ERR_CODE_SECOND_FACTOR_REQUIRED) {
			let resolver = error.resolver;

			if (
				resolver.hints[PHONE_AUTH_FACTOR_INDEX].factorId ===
				firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID
			) {
				// Setting the flag to notify the UI to update the view.
				updateState((ps) => ({
					...ps,
					isLoading: false,
					error: null, //No error here, the user should be sent the verification sms and redirected to verification code screen.
					twoFactorAuthRequired: true,
					secondFactorPhoneNumber:
						resolver.hints[PHONE_AUTH_FACTOR_INDEX].phoneNumber,
				}));

				// Keeping the resolver as it will be needed to verify the verification code.
				setGidState((ps) => ({
					...ps,
					resolver,
				}));

				// Sending the sms verification code.
				sendSmsVerificationCode(resolver);
			} else {
				// Unsupported second factor.
			}
		}

		//The case of regular sign in error.
		else {
			let firebaseError = structuredClone(error);

			try {
				firebaseError = {
					...firebaseError,
					message: JSON.parse(firebaseError.message),
				};
			} catch (err) {
				// Do nothing.
			}

			updateState((ps) => ({
				...ps,
				user: null,
				secondFactorPhoneNumber: null,
				isLoading: false,
				error: formatError(firebaseError, 'Login Failed!'),
				twoFactorAuthRequired: false,
			}));

			//Clearing all the 2FA info if the the sign in failed in case it was set before.
			setGidState((ps) => ({
				...ps,
				resolver: null,
				verificationId: null,
			}));
		}
	};

	// 2FA-RECAPTCHA: Reset the recaptcha.
	const initRecaptcha = () => {
		if (gidState.recaptchaVerifier) {
			return gidState.recaptchaVerifier.render();
		} else {
			let recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
				RECAPTCHA_VERIFIER_ID,
				{
					size: 'invisible',
					callback: (response) => {
						// This call back function is the same as the verify().then
						// console.log('Success CALLBACK:', response);
					},
					'error-callback': (e) => {
						console.log('Error Callback:', e);
						if (gidState.recaptchaVerifier) gidState.recaptchaVerifier.reset();
					},
					'expired-callback': () => {
						console.log('Expired Callback!');
						if (gidState.recaptchaVerifier) gidState.recaptchaVerifier.reset();
					},
				},
			);
			setGidState((ps) => ({ ...ps, recaptchaVerifier }));
			return recaptchaVerifier.render();
		}
	};

	// 2FA-RECAPTCHA: Clears the recaptcha from the UI.
	const clearRecaptcha = () => {
		if (gidState.recaptchaVerifier) gidState.recaptchaVerifier.clear();
		setGidState((ps) => ({ ...ps, recaptchaVerifier: null }));
	};

	// 2FA-RESET: Clears the state of the 2FA to allow the user go back to the sign in page.
	const quit2FA = () => {
		// Signing out from the firebase in case the user is signed in.
		// This case occurs in the scenario of the user is found without the second
		// factor and is requried to activate it.
		firebase.auth().signOut();

		//Clearing 2FA data from the state.
		setGidState((ps) => ({
			...ps,
			resolver: null,
			verificationId: null,
		}));

		//Updating the state with data.
		updateState((ps) => ({
			...ps,
			user: null,
			secondFactorPhoneNumber: null,
			error: null,
			twoFactorAuthRequired: false,
			twoFactorAuthActivationRequired: false,
		}));
	};

	// 2FA-SIGN-IN: Used to send or resend the sms verification code.
	const sendSmsVerificationCode = (resolver = gidState.resolver) => {
		if (resolver && !state.isSendingSms) {
			gidState.recaptchaVerifier
				.verify()
				.then(() => {
					updateState((ps) => ({
						...ps,
						isSendingSms: true,
						error: null,
					}));

					let phoneInfoOptions = {
						multiFactorHint: resolver.hints[PHONE_AUTH_FACTOR_INDEX],
						session: resolver.session,
					};

					// Send SMS verification code.
					let phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
					return phoneAuthProvider.verifyPhoneNumber(
						phoneInfoOptions,
						gidState.recaptchaVerifier,
					);
				})
				.then((verificationId) => {
					//Setting sending sms flag.
					updateState((ps) => ({
						...ps,
						isSendingSms: false,
						error: null,
					}));
					// Storing the verification ID which is going to be used later with the verification code.
					setGidState((ps) => ({
						...ps,
						verificationId,
					}));
				})
				.catch((smsSendError) => {
					//Displaying the error for the user.
					updateState((ps) => ({
						...ps,
						isSendingSms: false,
						error: formatError(
							smsSendError,
							'Sending Verification SMS Failed!',
						),
					}));
					//Clearning the id.
					setGidState((ps) => ({
						...ps,
						verificationId: null,
					}));
				});
		}
	};

	// 2FA-SIGN-IN: Verifies the code entered by the user with the sent sms.
	const verifySmsCode = (verificationCode) => {
		if (gidState.verificationId && gidState.resolver && verificationCode) {
			updateState((ps) => ({
				...ps,
				isLoading: true,
				error: null,
			}));

			// Ask user for the SMS verification code.
			let cred = firebase.auth.PhoneAuthProvider.credential(
				gidState.verificationId,
				verificationCode,
			);
			let multiFactorAssertion =
				firebase.auth.PhoneMultiFactorGenerator.assertion(cred);

			// Complete sign-in.
			gidState.resolver
				.resolveSignIn(multiFactorAssertion)

				.then((googleRes) => {
					// Preparing the refresh and the access tokens for sign in process.
					const refreshToken = googleRes.user.refreshToken;
					const accessToken = googleRes.user.getIdToken();
					return Promise.all([refreshToken, accessToken]);
				})

				.then((userCredentials) => {
					//Fetching user information from the backend.
					return authApi.login({
						refreshToken: userCredentials[0],
						accessToken: userCredentials[1],
					});
				})

				.then((res) => {
					//Updating the state with data.
					updateState((ps) => ({
						...ps,
						user: res.data,
						isLoading: false,
						error: null,
						twoFactorAuthRequired: false,
					}));

					//Clearing 2FA data from the state as they are not required any more after the verification and the sign in is successful.
					setGidState((ps) => ({
						...ps,
						resolver: null,
						verificationId: null,
					}));
				})

				.catch((e) => {
					//Checking if the error is reported by google or by the backend.
					const errorHeader = e.code
						? 'Invalid Verification Code.'
						: 'Login Failed!';

					//Resetting the state and reporting error.
					updateState((ps) => ({
						...ps,
						user: null,
						isLoading: false,
						error: formatError(e, errorHeader),
					}));
				});
		}
	};

	// 2FA-ENROLLMENT: Activate the user's phone number by sending him an sms.
	const activateUserPhoneNumber = (phoneNumber) => {
		let gUser = firebase.auth().currentUser;

		if (gUser && !state.isSendingSms) {
			return new Promise((resolve, reject) => {
				gidState.recaptchaVerifier
					.verify()
					.then(() => {
						//Setting the is loading flag.
						updateState((ps) => ({
							...ps,
							isSendingSms: true,
						}));

						//Getting the user multifactor session and sending an sms to his phone.
						return gUser.multiFactor.getSession();
					})
					.then((multiFactorSession) => {
						// Specify the phone number and pass the MFA session.
						let phoneInfoOptions = {
							phoneNumber: phoneNumber,
							session: multiFactorSession,
						};

						// Send SMS verification code.
						let phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
						return phoneAuthProvider.verifyPhoneNumber(
							phoneInfoOptions,
							gidState.recaptchaVerifier,
						);
					})
					.then((verificationId) => {
						updateState((ps) => ({
							...ps,
							isSendingSms: false,
							error: null,
							secondFactorPhoneNumber: maskPhoneNumber(phoneNumber),
						}));

						setGidState((ps) => ({
							...ps,
							verificationId,
						}));

						resolve();
					})
					.catch((e) => {
						//Resetting the state and reporting error.
						const errorHeader = 'Phone Number Verification Failed!';
						updateState((ps) => ({
							...ps,
							isSendingSms: false,
							error: formatError(e, errorHeader),
						}));

						//Clearing the verification id in case it was set before.
						setGidState((ps) => ({
							...ps,
							verificationId: null,
						}));

						//The error is reported
						reject(errorHeader);
					});
			});
		} else {
			return Promise.reject('User is not available.');
		}
	};

	// 2FA-ENROLLMENT: Verifying the phone number of the user.
	const verifyUserPhoneNumber = (verificationCode) => {
		let gUser = firebase.auth().currentUser;
		if (gUser && gidState.verificationId) {
			//Setting the is loading flag.
			updateState((ps) => ({
				...ps,
				isLoading: true,
			}));

			// Ask user for the verification code.
			const cred = firebase.auth.PhoneAuthProvider.credential(
				gidState.verificationId,
				verificationCode,
			);
			const multiFactorAssertion =
				firebase.auth.PhoneMultiFactorGenerator.assertion(cred);

			// Complete enrollment and then signing in the user.
			gUser.multiFactor
				.enroll(multiFactorAssertion, 'Second Factor Number')
				.then(() => {
					// Preparing the refresh and the access tokens for sign in process.
					const refreshToken = gUser.refreshToken;
					const accessToken = gUser.getIdToken();
					return Promise.all([refreshToken, accessToken]);
				})

				.then((userCredentials) => {
					//Fetching user information from the backend.
					return authApi.login({
						refreshToken: userCredentials[0],
						accessToken: userCredentials[1],
					});
				})

				// The user has successfully signed in.
				.then((res) => {
					//Updating the state with data.
					updateState((ps) => ({
						...ps,
						user: res.data,
						isLoading: false,
						error: null,
						twoFactorAuthActivationRequired: false,
					}));

					//Clearing 2FA data from the state after the number is verified.
					setGidState((ps) => ({
						...ps,
						resolver: null,
						verificationId: null,
					}));
				})

				.catch((e) => {
					//Checking if the error is reported by google or by the backend an picking the correct message for display.
					const errorHeader = e.code
						? 'Invalid Vrification Code.'
						: 'Login Failed!';

					//Resetting the state and reporting error.
					updateState((ps) => ({
						...ps,
						user: null,
						isLoading: false,
						error: formatError(e, errorHeader),
					}));
				});
		}
	};

	// 2FA-UN-ENROLL: Removes the second factor authentication.
	const resetSecondFactor = async () => {
		const gUser = firebase.auth().currentUser;

		if (gUser) {
			let options = gUser.multiFactor.enrolledFactors;
			if (options && options.length > 0) {
				updateState((ps) => ({
					...ps,
					isLoading: true,
					error: null,
				}));

				return gUser.multiFactor
					.unenroll(options[PHONE_AUTH_FACTOR_INDEX])
					.then(() => {
						updateState((ps) => ({
							...ps,
							isLoading: false,
							error: null,
						}));

						//Logging out the user after the 2FA is removed since the 2FA is mandatory.
						logout();
					})
					.catch((error) => {
						if (
							error.code === ERR_CODE_SECOND_FACTOR_RESET_RECENT_LOGIN_REQUIRED
						) {
							updateState((ps) => ({
								...ps,
								isLoading: false,
								error: null,
								recentLoginRequired: true,
							}));
						} else {
							updateState((ps) => ({
								...ps,
								isLoading: false,
								error: formatError(error, 'Second Factor Unenrollment Failed!'),
								recentLoginRequired: false,
							}));
						}
					});
			} else {
				console.log('Second factor is not available!');
			}
		} else {
			// In case the gUser is not available we ask the user to resign in.
			updateState((ps) => ({ ...ps, recentLoginRequired: true }));
		}
	};

	// 2FA-UN-ENROLL: Clears the flag indicating the recent login required when the message is acknowledged.
	const clearRecentLoginRequiredFlag = () =>
		updateState((ps) => ({ ...ps, recentLoginRequired: false }));

	const logout = async () => {
		updateState((ps) => ({
			...ps,
			isLoading: true,
			error: null,
		}));

		//Logging out from the backend.
		authApi
			.logout()
			.then(() => firebase.auth().signOut())
			.then(() => {
				//Resetting the entire state.
				updateState((ps) => ({
					...ps,
					user: null,
					secondFactorPhoneNumber: null,
					isLoading: false,
					error: null,
					success: defaultSuccess,
				}));
			})
			.catch((e) => {
				//Resetting the entire state.
				updateState((ps) => ({
					...ps,
					user: null,
					secondFactorPhoneNumber: null,
					isLoading: false,
					error: null, //No need to report error upon log out.
					success: defaultSuccess,
				}));
			})
			.finally(() => {
				//Clearing the current cookie in the document anyways in case the logout response is not received.
				// document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
				document.cookie = '';
				datadogLogs.clearUser();
				datadogRum.clearUser();
			});
	};

	const resetPassword = async (email) => {
		//Resetting password is only allowed for the external users. LXT users should not have a password.
		if (isLxtUser(email)) {
			updateState((ps) => ({
				...ps,
				error: formatError(
					{
						message:
							"LXT users are required to sign in by google and don't need a password.",
					},
					'Invalid Operation!',
				),
			}));
			return;
		}

		updateState((ps) => ({
			...ps,
			isLoading: true,
			error: null,
			success: defaultSuccess,
		}));

		await authApi
			.resetPassword(email)
			.then(() => {
				updateState((ps) => ({
					...ps,
					isLoading: false,
					error: null,
					success: {
						isSuccess: true,
						message:
							'Check your email, a link to reset your password has been sent',
					},
				}));
			})
			.catch((e) => {
				updateState((ps) => ({
					...ps,
					isLoading: false,
					error: formatError(e, 'Failed To Reset Password!'),
					success: defaultSuccess,
				}));
			});
	};

	const sendInvitation = async (email) => {
		updateState((ps) => ({
			...ps,
			isLoading: true,
			error: null,
			success: defaultSuccess,
		}));

		await authApi
			.sendInvitation(email)
			.then(() => {
				updateState((ps) => ({
					...ps,
					isLoading: false,
					error: null,
					success: {
						isSuccess: true,
						message:
							'Please check your email, a link to continue the registration process has been sent',
					},
				}));
			})
			.catch((e) => {
				updateState((ps) => ({
					...ps,
					isLoading: false,
					error: formatError(e, 'Failed To Send Invitation!'),
					success: defaultSuccess,
				}));
			});
	};

	const registerInvitedUser = (token, userData) => {
		updateState((ps) => ({
			...ps,
			isLoading: true,
			error: null,
			success: defaultSuccess,
		}));

		return new Promise((resolve, reject) => {
			usersApi
				.registerInvitedUser(token, userData)
				.then(() => {
					// Doing nothing more than resetting the loading flag as the user is successfully registered.
					// The UI should prompt the user to sign in after the registration.
					updateState((ps) => ({
						...ps,
						isLoading: false,
						user: null, // The user should only be set with the sign in.
						error: null,
					}));
					resolve();
				})
				.catch((e) => {
					updateState((ps) => ({
						...ps,
						user: null,
						isLoading: false,
						error: formatError(e, 'Failed To Create User Account!'),
					}));
					reject();
				});
		});
	};

	const getUserProfile = useCallback(() => {
		updateState((ps) => ({
			...ps,
			isLoading: true,
		}));
		return new Promise((resolve, reject) => {
			usersApi
				.getUserProfile()
				.then((res) => {
					updateState((ps) => ({
						...ps,
						isLoading: false,
						user: {
							...ps.user,
							email: res.data.email,
							pendingEmail: res.data.pendingEmail,
							gender: res.data.gender,
							dateOfBirth: res.data.dateOfBirth,
							originLocation: res.data.originLocation,
							currentLocation: res.data.currentLocation,
							firstName: res.data.firstName,
							lastName: res.data.lastName,
							skills: res.data.skills,
							nativeLanguage: res.data.nativeLanguage,
							otherLanguages: res.data.otherLanguages,
							education: res.data.education,
							otherEducation: res.data.otherEducation,
						},
						error: null,
					}));
				})
				.catch((e) => {
					updateState((ps) => ({
						...ps,
						isLoading: false,
						error: formatError(e, 'Failed To get user profile!'),
					}));
					reject();
				});
		});
	}, [updateState]);

	const updateUserProfile = (data) => {
		updateState((ps) => ({
			...ps,
			isLoading: true,
		}));

		return new Promise((resolve, reject) => {
			if (data) {
				usersApi
					.updateUserProfile(data)
					.then((res) => {
						updateState((ps) => ({
							...ps,
							isLoading: false,
							user: {
								...ps.user,
								...res.data,
							},
							error: null,
						}));
						resolve();
					})
					.catch((e) => {
						updateState((ps) => ({
							...ps,
							isLoading: false,
							error: formatError(e, 'Failed to update user profile!'),
						}));
						reject();
					});
			} else {
				resolve();
			}
		});
	};

	const clearSuccessMessage = () =>
		updateState((ps) => ({ ...ps, success: defaultSuccess }));

	const clearError = () => updateState((ps) => ({ ...ps, error: null }));

	const isLxtUser = (email) =>
		email && typeof email === 'string'
			? email.endsWith('@lxt.ai') || email.endsWith('@linguistixtank.com')
			: false;

	const services = {
		...state,
		RECAPTCHA_VERIFIER_ID,

		initializeAuth,
		getLoggedUser,
		getUserProfile,
		updateUserProfile,
		signInByGoogle,
		signInByEmailAndPassword,
		resetPassword,
		logout,
		sendInvitation,
		registerInvitedUser,
		clearSuccessMessage,
		clearError,
		refreshXsrfToken,

		sendSmsVerificationCode,
		verifySmsCode,
		activateUserPhoneNumber,
		verifyUserPhoneNumber,
		quit2FA,
		resetSecondFactor,
		clearRecentLoginRequiredFlag,

		initRecaptcha,
		clearRecaptcha,

		isLxtUser,
	};

	return (
		<AuthContext.Provider value={services}>{children}</AuthContext.Provider>
	);
};

const maskPhoneNumber = (phoneNumber) =>
	phoneNumber.substring(0, phoneNumber.length - 4).replace(/[a-z\d]/gi, '*') +
	phoneNumber.substring(phoneNumber.length - 4, phoneNumber.length);

const useAuthContext = () => useContext(AuthContext);

export { AuthContext as default, AuthContextProvider, useAuthContext };
