import React, { useCallback, useState,useEffect } from 'react';
import DOMPurify from 'dompurify';
import { readEml } from 'eml-parse-js';

const EmlViewer = ({ file,url }) => {
	const [content, setContent] = useState('');

	useEffect(() => {
		if (url) {
			fetch(url)
				.then((response) => response.text())
				.then(async (eml) =>
				{
					readEml(eml, (err, ReadEmlJson) =>
					{
						const sanitizedContent = DOMPurify.sanitize(
							ReadEmlJson.html || ReadEmlJson.text,
						);
						setContent(sanitizedContent);
					});
				})
				.catch((error) => console.error('Error fetching EML file:', error));
		}
	}, [url]);


	const handlefile = useCallback((file) =>
	{
		if (file) {
			const reader = new FileReader();
			reader.readAsText(file);

      reader.onload = () => {
				const stringifiedEML = reader.result;

				readEml(stringifiedEML, (err, ReadEmlJson) => {
					const sanitizedContent = DOMPurify.sanitize(
						ReadEmlJson.html || ReadEmlJson.text,
					);

					setContent(sanitizedContent);
				});
      };
		}
  },[]);

  if (file && !url) {
    handlefile(file);
	}

	return <div dangerouslySetInnerHTML={{ __html: content }} />;
};

export default EmlViewer;
